
* {
  box-sizing: border-box;
}


p, h1, h2, h3, h4, h5, h6, ul, ol, li, span {
  padding: 0;
  margin: 0;
}


a {
  text-decoration: none;
}


ul, ol {
  list-style-type: none;
}


body, html {
  scroll-behavior: smooth;
  font-size: 20px;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.content-wrapper {
  width: 1200px;
  margin: 0 auto;
}
