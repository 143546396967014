
.actionButton {
  .innerButton {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}


.error-message {
  color: #f44336;
  background-color: #fdecea;
  border: 1px solid #f44336;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* MOBILE */
@media not ((min-width: $media-width-transition) and (orientation: landscape)) {
  .only-desktop {
    display: none;
  }

  .actionButton {
    .innerButton {
      font-size: 1.1rem;

      .label {
        display: none;
      }
    }
  }

  .mdc-data-table__cell, .mdc-data-table__header-cell {
    padding: 0;
  }

  .gutter.gutter-vertical {
    background-color: map-get($mycofre-palette, 200);
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: row-resize;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "::::::::"; /* Carácter especial o texto */
      display: inline-block; /* Asegura que se renderice correctamente */
      font-size: 14px; /* Tamaño del carácter */
      margin: 0;
    }
  }

}

/* DESKTOP */
@media ((min-width: $media-width-transition) and (orientation: landscape)) {
  .only-mobile {
    display: none;
  }
}

